import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Everett({ styles, ...props }) {
  const Elements = useComponents()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page", { backgroundColor: "#ffffff" })}
    >
      <div
        className="seva-page-header formkit-page-header"
        style={{
          backgroundColor: styles.forElement("background").backgroundColor
        }}
      >
        <div
          style={styles.forElement("background")}
          className="formkit-background"
        />
        <div className="seva-container formkit-container">
          <Elements.Heading
            className="seva-header formkit-header"
            name="header"
            defaults={{ content: "Enter a catchy page headline" }}
          />
          <Elements.Region name="subheader" className="formkit-subheader">
            <Elements.Content
              defaults={{
                content: "Provide some more detail in this subtitle"
              }}
            />
          </Elements.Region>
        </div>
      </div>
      <div className="seva-content formkit-content">
        <div className="seva-container formkit-container">
          <div className="seva-content-column formkit-content-column">
            <Elements.Heading
              className="seva-content-header formkit-content-header"
              name="content_header"
              defaults={{ content: "Describe your product" }}
            />
            <Elements.Region
              className="seva-content-subheader formkit-content-subheader"
              name="content_subheader"
            >
              <Elements.Content
                defaults={{
                  content: "Add detailed content here"
                }}
              />
            </Elements.Region>
          </div>

          <div className="seva-content-column formkit-content-column">
            <Elements.Form
              className="seva-card formkit-card"
              style={styles.forElement("form", {
                backgroundColor: "#ffffff"
              })}
            >
              <Elements.Heading
                className="seva-form-header formkit-form-header"
                name="form_header"
                defaults={{ content: "Join the newsletter" }}
              />
              <Elements.Region
                className="seva-form-subheader formkit-form-subheader"
                name="form_subheader"
              >
                <Elements.Content
                  defaults={{
                    content: "Join my newsletter"
                  }}
                />
              </Elements.Region>
              <Elements.Errors />
              <Elements.CustomFields>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Subscribe" }}
                />
              </Elements.CustomFields>
              <Elements.Content
                name="disclaimer"
                defaults={{
                  content: "We respect your privacy. Unsubscribe at any time."
                }}
              />
            </Elements.Form>
          </div>
        </div>
      </div>
      <Elements.BuiltWith background="page" />
    </Elements.LandingPage>
  )
}

Everett.style = "landing_page"
Everett.categories = ["Webinar"]
Everett.thumbnail = ""
Everett.preview = "https://demo.ck.page/everett"
Everett.fields = [
  {
    name: "first_name",
    label: "Your first name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Everett, { name: "Everett" })
